"use strict";

/**
 * @author       [Robby Van den Broecke]
 * @date         [2024]
 * @link         [http://www.adequatelygood.com/JavaScript-Module-Pattern-In-Depth.html]
 * @namespace    [Blastic.Cardfilter]
 * @requires     [jQuery, Blastic]
 * @revision     [0.3]
 */

// @param ($): window.jQuery
// @param (ns): window.Blastic
window.Blastic = function ($, ns) {
  // 1. ECMA-262/5
  'use strict';

  // 2. CONFIGURATION
  const cfg = {
    cache: {
      container: '[data-component="card-filter"]',
      filterItem: '[data-trigger="filter"]',
      pagination: '.pagination',
      card: '.card',
      navbar: '.navbar'
    },
    events: {
      click: 'click'
    },
    classes: {
      active: 'active',
      hide: 'd-none'
    },
    data: {
      total: "total",
      pageSize: "pageSize",
      currentPage: "currentPage",
      page: "page",
      filter: "filter",
      filterValue: "filterValue"
    },
    options: {},
    template: {
      prev: '<li class="page-item {{disabled}}"><a class= "page-link pagination-arrow" data-page={{page}} href="#" aria-label="Previous"><span aria-hidden="true"><i class="bi bi-chevron-left"></i></span></a ></li>',
      next: '<li class="page-item {{disabled}}"><a class= "page-link pagination-arrow" data-page={{page}} href="#" aria-label="Next"><span aria-hidden="true"><i class="bi bi-chevron-right"></i></span></a ></li>',
      item: '<li class="page-item {{active}}"><a class="page-link" data-page={{page}} href="#">{{page}}</a></li>',
      spacer: '<li class="page-item"><a class="page-link" >...</a></li>'
    }
  };

  // 3. FUNCTIONS OBJECT
  ns.Cardfilter = {
    revision: 0.1,
    init: function () {
      this.cacheItems();
      if (this.container) {
        this.activate();
      }
    },
    cacheItems: function () {
      const {
        cache,
        data
      } = cfg;
      this.container = document.querySelector(cache.container);
      this.navbar = document.querySelector('.navbar');
      this.totalSize = this.container && this.container.dataset[data.total];
      this.pageSize = this.container && this.container.dataset[data.pageSize];
      this.currentPage = this.container && this.container.dataset[data.currentPage] ? this.container.dataset[data.currentPage] : 1;
      this.filterItems = this.container && this.container.querySelectorAll(cache.filterItem);
      this.pagination = this.container && this.container.querySelector(cache.pagination);
      this.cards = this.container && this.container.querySelectorAll(cache.card);
      this.currentFilter = "";
    },
    activate: function () {
      this.bindEvents();
      this.generateNavigation();
    },
    bindEvents: function () {
      const {
        events
      } = cfg;
      this.pagination.addEventListener(events.click, this.handlePaginationClick.bind(this));
      this.filterItems.forEach(filterItem => {
        filterItem.addEventListener(events.click, this.handleFilterItemClick.bind(this));
      });
    },
    handleFilterItemClick: function (event) {
      const {
        cache,
        classes,
        data
      } = cfg;
      event.preventDefault();
      const clickedItem = event.target.closest(cache.filterItem);
      if (!clickedItem) return;
      const isActive = clickedItem.classList.contains(classes.active);
      this.filterItems.forEach(item => {
        item.classList.remove(classes.active);
      });
      if (isActive) {
        this.currentFilter = "";
      } else {
        clickedItem.classList.add(classes.active);
        this.currentFilter = clickedItem.dataset[data.filterValue] || "";
      }
      console.log(this.currentFilter);
      this.currentPage = 1;
      this.updateCardVisibility();
    },
    handlePaginationClick: function (event) {
      const {
        data
      } = cfg;
      event.preventDefault();
      const target = event.target.closest(`[data-${data.page}]`);
      if (target) {
        const selectedPage = parseInt(target.getAttribute(`data-${data.page}`));
        this.currentPage = selectedPage;
        this.scrollToTop();
        this.updateCardVisibility();
        this.generateNavigation();
      }
    },
    generateNavigation: function () {
      const {
        template
      } = cfg;

      // Calculate total pages
      const totalPages = Math.ceil(this.totalSize / this.pageSize);

      // If there are no pages or only one page, don't show pagination
      if (totalPages <= 1) {
        this.pagination.innerHTML = "";
        return;
      }
      let paginationHTML = '';

      // Always add the "Previous" button
      const prevDisabled = this.currentPage === 1 ? 'disabled' : '';
      paginationHTML += ns.fn.renderTemplate({
        disabled: prevDisabled,
        page: this.currentPage - 1
      }, template.prev);

      // Function to render a page with the active class if it's the current page
      const renderPageItem = page => {
        const activeClass = page === this.currentPage ? 'active' : ''; // Add active class if it's the current page
        return ns.fn.renderTemplate({
          page,
          active: activeClass
        }, template.item);
      };

      // If total pages less than or equal to 5, show all pages
      if (totalPages <= 5) {
        for (let i = 1; i <= totalPages; i++) {
          paginationHTML += renderPageItem(i);
        }
      } else {
        // Always show the first page
        paginationHTML += renderPageItem(1);
        let pagesToShow = [];
        if (this.currentPage <= 3) {
          // Show pages 2, 3, 4
          pagesToShow = [2, 3, 4];
          for (let i of pagesToShow) {
            paginationHTML += renderPageItem(i);
          }
          // Add spacer
          paginationHTML += template.spacer;
        } else if (this.currentPage >= totalPages - 2) {
          // Add spacer
          paginationHTML += template.spacer;
          // Show pages totalPages -3, totalPages -2, totalPages -1
          pagesToShow = [totalPages - 3, totalPages - 2, totalPages - 1];
          for (let i of pagesToShow) {
            paginationHTML += renderPageItem(i);
          }
        } else {
          // Add spacer before middle pages
          paginationHTML += template.spacer;
          // Show pages currentPage -1, currentPage, currentPage +1
          pagesToShow = [this.currentPage - 1, this.currentPage, this.currentPage + 1];
          for (let i of pagesToShow) {
            paginationHTML += renderPageItem(i);
          }
          // Add spacer after middle pages
          paginationHTML += template.spacer;
        }

        // Always show the last page
        paginationHTML += renderPageItem(totalPages);
      }

      // Always add the "Next" button
      const nextDisabled = this.currentPage === totalPages ? 'disabled' : '';
      paginationHTML += ns.fn.renderTemplate({
        disabled: nextDisabled,
        page: this.currentPage + 1
      }, template.next);

      // Insert the generated pagination into the DOM
      this.pagination.innerHTML = paginationHTML;
    },
    updateCardVisibility: function () {
      const {
        classes,
        data
      } = cfg;
      let visibleCards = []; // Store visible cards based on the current filter

      // First, check each card for visibility
      this.cards.forEach(card => {
        const cardParent = card.parentNode;

        // Check if the card matches the current filter
        const matchesFilter = !this.currentFilter || card.dataset[data.filter].split(',').map(val => val.trim()).includes(this.currentFilter);

        // Initially hide all cards
        cardParent.classList.add(classes.hide);

        // If the card matches the filter, push it to the visibleCards array
        if (matchesFilter) {
          visibleCards.push(card);
        }
      });

      // Update totalSize based on visible cards
      this.totalSize = visibleCards.length;

      // Ensure that totalSize and pageSize are integers
      this.totalSize = parseInt(this.totalSize, 10);
      this.pageSize = parseInt(this.pageSize, 10);

      // Determine the start and end index for pagination
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = Math.min(startIndex + this.pageSize, this.totalSize);

      // Show only the cards that should be visible on the current page
      visibleCards.forEach((card, index) => {
        const cardParent = card.parentNode;
        if (index >= startIndex && index < endIndex) {
          cardParent.classList.remove(classes.hide);
        }
      });

      // Regenerate pagination after filtering
      this.generateNavigation();
    },
    scrollToTop: function () {
      const navbarHeight = this.navbar ? this.navbar.offsetHeight : 0;
      const scrollOffset = this.container ? this.container.getBoundingClientRect().top + window.scrollY - navbarHeight : 0;
      window.scrollTo({
        top: scrollOffset,
        behavior: 'smooth'
      });
    }
  };

  // 4. NAMESPACE
  return ns;
}(window.jQuery, window.Blastic || {});