"use strict";

/**
 * @author       [Robby Vandenbroecke]
 * @date         [2023]
 * @link         [http://www.adequatelygood.com/JavaScript-Module-Pattern-In-Depth.html]
 * @namespace    [Blastic.Forms]
 * @requires     [jQuery, Blastic]
 * @revision     [0.1]
 */

// @param ($): window.jQuery
// @param (ns): window.Blastic
window.Blastic = function ($, ns) {
  // 1. ECMA-262/5
  'use strict';

  // 2. CONFIGURATION
  const cfg = {
    cache: {
      container: 'form',
      mui: '.MuiInputBase-input',
      muiFormControl: '.MuiFormControl-root',
      validationFields: '[data-validate]',
      reCaptchaField: '[name="recaptchaResponse"]',
      submitbutton: '[type=submit]',
      successMessage: '.formSubmitSuccess',
      errorMessage: '.formSubmitError'
    },
    events: {
      blur: 'blur',
      focus: 'focus',
      input: 'input',
      submit: 'submit'
    },
    classes: {
      shrink: 'shrink',
      error: 'error',
      disabled: 'disabled'
    },
    options: {
      reCaptchaKey: '6Lfi03UqAAAAAFxeLhBq8euTrjKIFreox7LMthkQ',
      ajax: {
        reCaptcha: {
          dataType: 'script',
          cache: true,
          url: '//www.google.com/recaptcha/api.js?render={{siteKey}}'
        }
      }
    },
    data: {
      validate: 'validate',
      formType: 'form-type',
      isAjax: 'is-ajax',
      downloadFile: 'download-file'
    }
  };

  // 3. FUNCTIONS OBJECT
  ns.Forms = {
    revision: 0.2,
    init: function () {
      this.cacheItems();
      if (this.container.length) {
        this.activate();
      }
      if (this.successMessage.length && !this.isAjax) {
        this.sendGtmForm(1);
      }
      if (this.errorMessage.length && !this.isAjax) {
        this.sendGtmForm(0);
      }
    },
    cacheItems: function () {
      const {
        cache,
        data
      } = cfg;
      this.container = $(cache.container);
      this.mui = this.container.find(cache.mui);
      this.validationFields = this.container.find(cache.validationFields);
      this.reCaptchaField = this.container.find(cache.reCaptchaField);
      this.submitbutton = this.container.find(cache.submitbutton);
      this.successMessage = this.container.find(cache.successMessage);
      this.errorMessage = this.container.find(cache.errorMessage);
      this.isAjax = this.container.data(data.isAjax);
      this.formType = this.container.data(data.formType);
      this.downloadFile = this.container.data(data.downloadFile);
    },
    activate: function () {
      const {
        options
      } = cfg;
      this.bindEvents();
      this.validateForm();
      if (this.container && this.container.length) {
        const ajaxOptions = $.extend({}, options.ajax.reCaptcha, {
          url: ns.fn.renderTemplate({
            siteKey: options.reCaptchaKey
          }, options.ajax.reCaptcha.url)
        });
        this.getScripts(ajaxOptions);
      }
    },
    bindEvents: function () {
      const {
        events
      } = cfg;
      this.mui.on(`${events.focus} ${events.blur}`, this.handleMui.bind(this));
      this.validationFields.on(events.input, this.handleValidationFields.bind(this));
      this.container.on(events.submit, this.handleSubmit.bind(this));
    },
    handleMui: function (ev) {
      const {
        classes,
        events
      } = cfg;
      const label = $(`label[for=${ev.currentTarget.name}]`);
      label.toggleClass(classes.shrink, ev.type === events.focus);
    },
    handleValidationFields: function (ev) {
      const {
        cache,
        classes,
        data
      } = cfg;
      const target = $(ev.currentTarget);
      const parent = target.closest(cache.muiFormControl);
      const validationType = target.data(data.validate);
      let val = target.val();
      // Name validation 
      if (validationType == "name") {
        const regexp = new RegExp(/[^a-zA-Z ďż˝ďż˝ďż˝ďż˝ďż˝`ďż˝ďż˝ďż˝ďż˝ďż˝ďż˝ďż˝ďż˝ďż˝ďż˝ďż˝ďż˝ďż˝ďż˝ďż˝ďż˝\']/g);
        val = val.replace(regexp, "");
        if (regexp.test(ev.originalEvent.key)) {
          ev.preventDefault();
        }
        target.val(val);
        parent.toggleClass(classes.error, !val);
      }
      // email validation
      else if (validationType == "email") {
        parent.toggleClass(classes.error, !this.isValidEmail(val));
      } else if (validationType == "checkbox") {
        const isChecked = target.prop("checked");
        parent.toggleClass(classes.error, !isChecked);
      }
      // default empty validation
      else {
        parent.toggleClass(classes.error, !val);
      }
      this.validateForm();
    },
    handleSubmit: function (e) {
      const {
        cache,
        events,
        options
      } = cfg;
      e.preventDefault(); // Prevent the default form submission behavior

      const that = this;

      // Check if the form should be submitted via AJAX
      if (this.isAjax) {
        grecaptcha.ready(function () {
          // Wait for the recaptcha to be ready
          grecaptcha.execute(options.reCaptchaKey, {
            action: "contact"
          }) // Execute the recaptcha
          .then(function (token) {
            if (!token) {
              console.error('Something went wrong with the reCapthca request');
            } else {
              that.reCaptchaField.val(token);

              // Gather form data
              const formData = that.container.serialize();

              // Perform the AJAX request
              $.ajax({
                type: "POST",
                url: that.container.attr("action"),
                data: formData,
                // Form data
                success: function (response) {
                  //console.log("Form submitted successfully:", response);
                  that.container.find(cache.successMessage).show();
                  that.container.find(cache.errorMessage).hide();
                  that.sendGtmForm(1);

                  // reset form
                  that.container[0].reset();

                  // download file if configured
                  if (that.downloadFile) {
                    // Decode the Base64 string to get the original URL
                    const decodedDownloadLink = atob(that.downloadFile);
                    const downloadLink = document.createElement("a");
                    downloadLink.href = decodedDownloadLink;
                    downloadLink.download = "";
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                  }
                },
                error: function (xhr, status, error) {
                  //console.error("Form submission failed:", error);
                  that.container.find(cache.successMessage).hide();
                  that.container.find(cache.errorMessage).show();
                  that.sendGtmForm(0);
                }
              });
            }
          });
        });
      } else {
        // Non-AJAX form submission
        /*that.container.off(events.submit);
        that.container.submit();*/

        grecaptcha.ready(function () {
          // Wait for the recaptcha to be ready
          grecaptcha.execute(options.reCaptchaKey, {
            action: "contact"
          }) // Execute the recaptcha
          .then(function (token) {
            if (!token) {
              console.error('Something went wrong with the reCapthca request');
            } else {
              that.reCaptchaField.val(token);

              // actually submit the form
              that.container.unbind(events.submit);
              that.container.submit();
            }
          });
        });
      }
    },
    isValidEmail: function (emailString) {
      var emailReg = /^[A-Za-z0-9\u0430-\u044F\u0410-\u042F\._-]+@([A-Za-z0-9\u0430-\u044F\u0410-\u042F]{1,2}|[A-Za-z0-9\u0430-\u044F\u0410-\u042F]((?!(\.\.))[A-Za-z0-9\u0430-\u044F\u0410-\u042F.-])+[A-Za-z0-9\u0430-\u044F\u0410-\u042F])\.[A-Za-z\u0430-\u044F\u0410-\u042F]{2,}$/i;
      return emailReg.test(emailString);
    },
    validateForm: function () {
      const {
        classes,
        data
      } = cfg;
      let hasError = false;
      for (let i = 0; i < this.validationFields.length; i++) {
        const element = this.validationFields.eq(i);
        const validationType = element.data(data.validate);
        if (validationType == "email") {
          if (!this.isValidEmail(element.val())) {
            hasError = true;
          }
        } else if (validationType == "checkbox") {
          if (!element.prop("checked")) {
            hasError = true;
          }
        } else {
          if (!element.val()) {
            hasError = true;
          }
        }
      }
      this.submitbutton.toggleClass(classes.disabled, hasError);
    },
    getScripts: function (scripts) {
      function errorHandler(n, t, scripts) {
        throw new Error(scripts.url);
      }
      $.ajax(scripts).fail(errorHandler);
    },
    sendGtmForm: function (type) {
      const consoleInfoStyle = 'padding:2px 5px;border-radius:2px;background-color:#0b2d44;color:#fff;font-weight:700';
      const eventValue = type ? 'submit-success' : 'submit-fail';

      //const formId = this.container.attr('id') || 'unknown-form';
      const formId = this.formType ? this.formType : 'contact form';
      const formData = this.container.serialize();
      const isConsentGranted = ns.Cookiebox.getConsentStatus();
      window.dataLayer = window.dataLayer || [];

      // Prepare event object
      const eventObject = {
        'event': eventValue,
        'formId': formId
      };

      // Only include formData if consent is granted
      if (isConsentGranted) {
        eventObject.formData = formData; // Include actual form data
      } else {
        console.warn('User did not consent to form data tracking.');
      }
      console.info('%cdatalayer%c %o', consoleInfoStyle, '', eventObject);
      window.dataLayer.push(eventObject);
    }
  };

  // 4. NAMESPACE
  return ns;
}(window.jQuery, window.Blastic || {});