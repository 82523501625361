"use strict";

/**
 * @author       [Robby Van den Broecke]
 * @date         [2024]
 * @link         [http://www.adequatelygood.com/JavaScript-Module-Pattern-In-Depth.html]
 * @namespace    [Blastic.PromoBanner]
 * @requires     [jQuery, Blastic]
 * @revision     [0.1]
 */

// @param ($): window.jQuery
// @param (ns): window.Blastic
window.Blastic = function ($, ns, bs) {
  // 1. ECMA-262/5
  'use strict';

  // 2. CONFIGURATION
  const cfg = {
    cache: {
      container: '[data-component="promo-banner"]',
      video: 'video'
    },
    events: {
      modal: {
        hide: 'hide.bs.modal'
      }
    },
    classes: {},
    options: {
      session: {
        name: 'modalShown'
      }
    },
    data: {
      key: 'key'
    }
  };

  // 3. FUNCTIONS OBJECT
  ns.PromoBanner = {
    revision: 0.1,
    init: function () {
      this.cacheItems();
      if (this.container) {
        this.activate();
      }
    },
    cacheItems: function () {
      const {
        cache,
        data
      } = cfg;
      this.container = document.querySelector(cache.container);
      this.modalKey = this.container && this.container.dataset[data.key];
      this.modalInstance = this.container && bs.Modal.getOrCreateInstance(this.container);
      this.video = this.container && this.container.querySelector(cache.video);
    },
    activate: function () {
      this.displayModel();
      this.bindEvents();
    },
    bindEvents: function () {
      const {
        events
      } = cfg;
      this.container && this.container.addEventListener(events.modal.hide, this.handleModalClose.bind(this));
    },
    displayModel: function () {
      const {
        classes
      } = cfg;
      const modalStatus = this.getModalStatus();
      if (!modalStatus) {
        if (this.modalInstance) {
          this.modalInstance.show();
        } else {
          console.error('Modal instance failed to initialize.');
        }
      }
    },
    getModalStatus: function () {
      const {
        options
      } = cfg;
      return window.sessionStorage.getItem(`${options.session.name}-${this.modalKey}`);
    },
    handleModalClose: function () {
      const {
        options
      } = cfg;
      window.sessionStorage.setItem(`${options.session.name}-${this.modalKey}`, true);
      if (this.video) {
        this.video.pause();
        this.video.currentTime = 0;
      }
    }
  };

  // 4. NAMESPACE
  return ns;
}(window.jQuery, window.Blastic, window.bootstrap || {});