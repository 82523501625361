"use strict";

/**
 * @author       [Tim Vermaelen]
 * @date         [2016-2022]
 * @link         [http://www.adequatelygood.com/JavaScript-Module-Pattern-In-Depth.html]
 * @namespace    [Blastic]
 * @requires     [jQuery]
 * @revision     [0.7]
 */

// @param ($): jQuery
// @param (ns): namespace
window.Blastic = function ($, ns) {
  // 1. ECMA-262/5
  'use strict';

  // 2. PRIVATE CONFIGURATION OVERRIDE
  const cfg = {
    owlCarousel: {
      loop: true,
      margin: 10,
      nav: false,
      autoHeight: true,
      autoHeightClass: 'h-100',
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        992: {
          items: 3
        }
      }
    }
  };

  // 3. LOAD COMPONENTS
  ns.components = function () {
    ns.Cookiebox.init();
    ns.Forms.init();
    ns.Navbar.init();
    ns.Cardfilter.init();
    ns.PromoBanner.init();
  };

  // 4. LOAD CLASSES
  ns.classes = function () {
    return {};
  };

  // 5. ONCE THE DOM IS READY
  $(function () {
    ns.components();
    //ns.classes();
    $('.owl-carousel').owlCarousel(cfg.owlCarousel);
    document.querySelectorAll('.card-picture').forEach(card => {
      const hoverElement = card.querySelector('.card-body-hover');
      card.addEventListener('mouseenter', () => {
        hoverElement.style.maxHeight = hoverElement.scrollHeight + 'px'; // Set to content height
      });
      card.addEventListener('mouseleave', () => {
        hoverElement.style.maxHeight = '0'; // Collapse
      });
    });
  });

  // 6. GLOBALIZE NAMESPACE
  return ns;
}(window.jQuery, window.Blastic || {});