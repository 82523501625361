"use strict";

window.Blastic = function ($, ns) {
  'use strict';

  const cfg = {
    cache: {
      container: '.cookiebox',
      accept: '#cookiebox-accept',
      preference: '#cookiebox-pref',
      options: {
        gdpr: '#check-gdpr'
      }
    },
    events: {
      click: 'click',
      dataLayer: {
        accept: 'cookie-consent-accept',
        refuse: 'cookie-consent-refuse',
        consent: 'consent',
        granted: 'granted',
        denied: 'denied'
      }
    },
    classes: {
      hidden: 'd-none'
    },
    options: {
      consent: {
        name: 'PlatteauCookieConsent'
      }
    }
  };
  ns.Cookiebox = {
    revision: 0.2,
    init: function () {
      this.cacheItems();
      if (this.container) {
        this.activate();
      }
    },
    cacheItems: function () {
      const {
        cache
      } = cfg;
      this.container = document.querySelector(cache.container);
      this.accept = document.querySelector(cache.accept);
      this.preference = document.querySelector(cache.preference);
      this.options = {
        gdpr: document.querySelector(cache.options.gdpr)
      };
    },
    activate: function () {
      this.verifyPreference();
      this.bindEvents();
    },
    bindEvents: function () {
      const {
        events
      } = cfg;
      this.preference.addEventListener(events.click, this.handlePreference.bind(this));
      this.accept.addEventListener(events.click, this.handleAccept.bind(this));
    },
    verifyPreference: function () {
      const {
        classes,
        events
      } = cfg;
      const cbStatus = this.getConsentStatus();
      if (!cbStatus) {
        $(this.container).removeClass(classes.hidden);
      }

      // Update Google Consent Mode on each page load
      const isGdprChecked = cbStatus === "true";
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': events.dataLayer.consent,
        'consent': {
          'analytics_storage': isGdprChecked ? events.dataLayer.granted : events.dataLayer.denied,
          'ad_storage': isGdprChecked ? events.dataLayer.granted : events.dataLayer.denied
        }
      });
    },
    getConsentStatus: function () {
      const {
        options
      } = cfg;
      return window.localStorage.getItem(options.consent.name);
    },
    handlePreference: function () {
      const {
        events,
        options
      } = cfg;
      const isGdprChecked = this.options.gdpr.checked;
      const dlEvent = isGdprChecked ? events.dataLayer.accept : events.dataLayer.refuse;
      window.localStorage.setItem(options.consent.name, isGdprChecked);

      // Update Google Consent 
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': events.dataLayer.consent,
        'consent': {
          'analytics_storage': isGdprChecked ? events.dataLayer.granted : events.dataLayer.denied,
          'ad_storage': isGdprChecked ? events.dataLayer.granted : events.dataLayer.denied
        }
      });
      this.updateDataLayer(dlEvent);
      $(this.container).hide();
    },
    handleAccept: function () {
      const {
        events,
        options
      } = cfg;
      window.localStorage.setItem(options.consent.name, true);

      // Update Google Consent Mode
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': events.dataLayer.consent,
        'consent': {
          'analytics_storage': events.dataLayer.granted,
          'ad_storage': events.dataLayer.granted
        }
      });
      this.updateDataLayer(events.dataLayer.accept);
      $(this.container).hide();
    },
    updateDataLayer: function (event) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': event
      });
    }
  };
  return ns;
}(window.jQuery, window.Blastic || {});