"use strict";

/**
 * @author       [Robby Van den Broecke]
 * @date         [2024]
 * @link         [http://www.adequatelygood.com/JavaScript-Module-Pattern-In-Depth.html]
 * @namespace    [Blastic.Navbar]
 * @requires     [jQuery, Blastic, Bootstrap]
 * @revision     [0.5]
 */

// @param ($): window.jQuery
// @param (ns): window.Blastic
// @param ($bs): window.bootstrap
window.Blastic = function ($, ns, $bs) {
  // 1. ECMA-262/5
  'use strict';

  // 2. CONFIGURATION
  const cfg = {
    cache: {
      container: '.navbar',
      dropdown: '.navbar .dropdown',
      dropdownHover: '.navbar .dropdown-hover, .navbar .dropdown-hover-all .dropdown'
    },
    classes: {
      hasChildDropdownShow: 'has-child-dropdown-show',
      show: 'show',
      levelOpen: 'level-open'
    },
    events: {
      click: 'click',
      mouseEnter: 'mouseenter',
      mouseLeave: 'mouseleave',
      hideDropdown: 'hide.bs.dropdown',
      hiddenDropdown: 'hidden.bs.dropdown'
    }
  };

  // 3. FUNCTIONS OBJECT
  ns.Navbar = {
    revision: 0.5,
    init: function () {
      this.cacheItems();
      if (this.container) {
        this.activate();
      }
    },
    cacheItems: function () {
      const {
        cache
      } = cfg;
      this.container = document.querySelector(cache.container);
      this.hoverElements = document.querySelectorAll(cache.dropdownHover);
    },
    activate: function () {
      this.modifyDropdownBehavior();
      this.bindEvents();
    },
    bindEvents: function () {
      const {
        events
      } = cfg;

      // Bind hide.bs.dropdown event
      document.querySelectorAll(cfg.cache.dropdown).forEach(function (dd) {
        dd.addEventListener(events.hideDropdown, ns.Navbar.handleDropdownHide);
        dd.addEventListener(events.hiddenDropdown, ns.Navbar.handleDropdownHidden);
      });

      // Bind mouseenter and mouseleave events for hover elements
      this.hoverElements.forEach(function (dd) {
        dd.addEventListener(events.mouseEnter, ns.Navbar.handleMouseEnter);
        dd.addEventListener(events.mouseLeave, ns.Navbar.handleMouseLeave);
      });
    },
    // Modify Bootstrap dropdown behavior for multi-level dropdowns
    modifyDropdownBehavior: function () {
      const {
        classes
      } = cfg;
      $bs.Dropdown.prototype.toggle = function (_original) {
        return function () {
          // Remove the class from all dropdowns
          document.querySelectorAll('.navbar .' + classes.hasChildDropdownShow).forEach(function (e) {
            e.classList.remove(classes.hasChildDropdownShow);
          });

          // Add the class to the parent dropdown if applicable
          let dd = this._element.closest('.dropdown')?.parentNode?.closest('.dropdown');
          for (; dd && dd !== document; dd = dd.parentNode.closest('.dropdown')) {
            dd.classList.add(classes.hasChildDropdownShow);
          }

          // Remove the open class from all dropdowns
          document.querySelectorAll('.navbar .' + classes.levelOpen).forEach(function (e) {
            e.classList.remove(classes.levelOpen);
          });

          // add the open class for existing open item
          document.querySelectorAll('.navbar .dropdown-toggle.show').forEach(function (e) {
            e.parentNode?.classList.add(classes.levelOpen);
          });
          this._element.closest('.dropdown')?.classList.add(classes.levelOpen);

          // Call the original toggle method
          return _original.call(this);
        };
      }($bs.Dropdown.prototype.toggle);
    },
    // Handle hide.bs.dropdown event
    handleDropdownHide: function (e) {
      const {
        classes
      } = cfg;
      if (this.classList.contains(classes.hasChildDropdownShow)) {
        this.classList.remove(classes.hasChildDropdownShow);
        e.preventDefault();
      }
      e.stopPropagation();
    },
    handleDropdownHidden: function () {
      const {
        classes
      } = cfg;
      if (!this.querySelector('.dropdown-toggle').classList.contains(classes.show)) {
        this.classList.remove(classes.levelOpen);
      }
    },
    // Handle mouseenter event for hover dropdown
    handleMouseEnter: function (e) {
      const {
        classes
      } = cfg;
      let toggle = e.target.querySelector(':scope>[data-bs-toggle="dropdown"]');
      if (!toggle.classList.contains(classes.show)) {
        $bs.Dropdown.getOrCreateInstance(toggle).toggle();
        this.classList.add(classes.hasChildDropdownShow);
        $bs.Dropdown.clearMenus();
      }
    },
    // Handle mouseleave event for hover dropdown
    handleMouseLeave: function (e) {
      const {
        classes
      } = cfg;
      let toggle = e.target.querySelector(':scope>[data-bs-toggle="dropdown"]');
      if (toggle.classList.contains(classes.show)) {
        $bs.Dropdown.getOrCreateInstance(toggle).toggle();
      }
    }
  };
  return ns;
}(window.jQuery, window.Blastic || {}, window.bootstrap);