"use strict";

/**
 * @author       [Tim Vermaelen]
 * @date         [2023]
 * @link         [http://www.adequatelygood.com/JavaScript-Module-Pattern-In-Depth.html]
 * @namespace    [Blastic.fn]
 * @requires     [jQuery, Blastic]
 * @revision     [1.6]
 */

// @param ($): window.jQuery
// @param (ns): window.Blastic
window.Blastic = function ($, ns) {
  // 1. ECMA-262/5
  'use strict';

  // 2. CONFIGURATION
  const cfg = {
    cache: {
      culture: {
        selected: '[name="SelectedRegionGuid"]'
      }
    },
    classes: {
      xpEditor: 'page-builder'
    },
    data: {
      value: 'value',
      culture: 'culture'
    },
    delimiter: {
      key: '&',
      val: '='
    },
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1300
    }
  };

  // 3. FUNCTIONS OBJECT
  ns.fn = {
    revision: 1.6,
    /**
     * Are we in XP Editor or not
     * @property {Boolean}
     */
    isExperienceEditor: function () {
      return document.documentElement.classList.contains(cfg.classes.xpEditor);
    }(),
    /**
     * Returns the querystring as object literal
     * @returns {Object} querystring
     */
    getQsAsLiteral: function () {
      const url = decodeURIComponent(document.URL.replace(/\+/g, ' '));
      const arr = url.split('?');
      const converted = arr.length === 2 && this.decodeHtmlEntities(arr[1]);
      return converted && ns.fn.convertQsToLiteral(converted);
    },
    /**
     * Use an HTML tag to decode entities
     * @param {String} str
     * @returns {String} decoded string
     */
    decodeHtmlEntities: (() => {
      const tag = document.createElement('div');
      const htmlEntitiesPattern = /&(?:#x[a-f\d]+|#\d+|[a-z\d]+);?/gi;
      return function decodeHTMLEntities(str) {
        str = str.replace(htmlEntitiesPattern, m => {
          tag.innerHTML = m;
          return tag.textContent;
        });
        tag.textContent = '';
        return str;
      };
    })(),
    /**
     * Render html template with json data
     * @see handlebars or mustache if you need more advanced functionlity
     * @param {Object} obj : object literal
     * @param {String} template : html template with {{keys}} matching the object
     * @return {String} template : the template string replaced by key:value pairs from the object
     */
    renderTemplate: function (obj, template) {
      let tempKey, reg, key, val;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) {
          tempKey = String('{{' + key + '}}');
          reg = new RegExp(tempKey, 'g');
          val = obj[key] === undefined || obj[key] === null ? '' : obj[key];
          template = template.replace(reg, val);
        }
      }
      return template;
    },
    /**
     * Polyfill for window.location
     * @inner {string} origin fix IE11 on windows 10 issue
     * @inner {string} hash fix # inconsistency
     * @return {Object<Location>} window.location
     * @see https://connect.microsoft.com/IE/feedback/details/1763802/location-origin-is-undefined-in-ie-11-on-windows-10-but-works-on-windows-7
     * @see https://stackoverflow.com/questions/1822598/getting-url-hash-location-and-using-it-in-jquery
     */
    location: function (loc) {
      return loc.origin ? loc : function () {
        const origin = loc.protocol + '//' + loc.hostname + (loc.port ? `:${loc.port}` : '');
        const hash = loc.hash.replace('#', '');
        try {
          Object.defineProperty(loc, {
            origin: {
              value: origin,
              enumerable: true
            },
            hash: {
              value: '#' + hash,
              enumerable: true
            }
          });
        } catch (e) {
          loc.origin = origin;
          loc.hash = hash;
        }
        return loc;
      }();
    }(window.location),
    /**
     * Creates a URL object
     * @param {string} url document.URL or provided as param
     * @returns {Object} URL
     */
    url: function (url) {
      try {
        return new URL(url || document.URL);
      } catch (error) {
        console.warn(error);
      }
    },
    /**
     * Compares two object literals for properties and values, regardless of the order
     * @param {Object<key, val>} obj1 object literal
     * @param {Object<key, val>} obj2 object literal
     * @returns {Boolean}
     */
    compareObjectEquals(obj1, obj2) {
      const JSONStringifyByOrder = obj => {
        const keys = {};
        JSON.stringify(obj, (key, value) => {
          keys[key] = null;
          return value;
        });
        return JSON.stringify(obj, Object.keys(keys).sort());
      };
      return JSONStringifyByOrder(obj1) === JSONStringifyByOrder(obj2);
    },
    /**
     * A (possibly faster) way to get the current timestamp as an integer.
     * @returns {number} epoch time stamp in seconds
     */
    now: Date.now || function () {
      return new Date().getTime();
    },
    /**
     * Defers a function, scheduling it to run after the current call stack has cleared.
     * @param {Function} func : sheduled function
     * @returns {Function} delayed function
     */
    defer: function (func) {
      return this.delay.apply(null, [func, 1].concat([].slice.call(arguments, 1)));
    },
    /**
     * Delays a function for the given number of milliseconds, and then calls it with the arguments supplied.
     * @param {function()} func : function to delay
     * @param {number} wait : milliseconds
     * @returns {function()} delayed function
     */
    delay: function (func, wait) {
      const args = [].slice.call(arguments, 2);
      return setTimeout(function () {
        return func.apply(null, args);
      }, wait);
    },
    /**
     * Returns a function, that, when invoked, will only be triggered at most once during a given window of time.
     * @param {Function} func : function to throttle
     * @param {Integer} wait : milliseconds
     * @param {Object} options : options.leading to disable the execution on the leading or trailing edge.
     * @returns {function} lambda constructor
     */
    throttle: function (func, wait, options) {
      let context, args, result;
      let timeout = null;
      let previous = 0;
      options = options || {};
      const later = function () {
        previous = options.leading === false ? 0 : ns.fn.now();
        timeout = null;
        result = func.apply(context, args);
        context = args = null;
      };
      return function () {
        const now = ns.fn.now();
        if (!previous && options.leading === false) {
          previous = now;
        }
        const remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0) {
          clearTimeout(timeout);
          timeout = null;
          previous = now;
          result = func.apply(context, args);
          context = args = null;
        } else if (!timeout && options.trailing !== false) {
          timeout = setTimeout(later, remaining);
        }
        return result;
      };
    },
    /**
     * Returns a function, that, as long as it continues to be invoked, will not be triggered.
     * The function will be called after it stops being called for N milliseconds.
     * @param {function} func - function to debounce
     * @param {Integer} wait - milliseconds
     * @param {Boolean} immediate - if immediate is passed, trigger the function on the leading edge, instead of the trailing.
     * @return {function} result - applied function object
     * @returns {Function} lambda constructor
     */
    debounce: function (func, wait, immediate) {
      const self = this;
      let timeout, args, context, timestamp, result;
      const later = function () {
        const last = self.now() - timestamp;
        if (last < wait) {
          timeout = setTimeout(later, wait - last);
        } else {
          timeout = null;
          if (!immediate) {
            result = func.apply(context, args);
            context = args = null;
          }
        }
      };
      return function () {
        context = this;
        args = arguments;
        timestamp = self.now();
        const callNow = immediate && !timeout;
        if (!timeout) {
          timeout = setTimeout(later, wait);
        }
        if (callNow) {
          result = func.apply(context, args);
          context = args = null;
        }
        return result;
      };
    },
    /**
     * Delay events with the same id, good for window resize events, keystroke, etc
     * @param {Function} func : callback function to be run when done
     * @param {Integer} wait : integer in milliseconds
     * @param {String} id : unique event id
     */
    delayedEvent: function () {
      const timers = {};
      return function (func, wait, id) {
        wait = wait || 200;
        id = id || 'anonymous';
        if (timers[id]) {
          clearTimeout(timers[id]);
        }
        timers[id] = setTimeout(func, wait);
      };
    }(),
    /**
     * Convert a query alike string to an object literal
     * @param {String} qs : a query string of key value pairs (without ?)
     * @param {String} keyDelimiter : character between values and keys
     * @param {String} valDelimiter : character between keys and values
     * @see JSON.stringify works on a deeper level
     * @see $.param is the reverse for this function
     * @example: key1=val1&key2=val2&key3=val3
     * @return {Object<key, val>} obj : object literal representing the query string
     */
    convertQsToLiteral: function (qs, keyDelimiter, valDelimiter) {
      const obj = {};
      if (qs && qs.length) {
        qs = qs.split("#")[0]; // filter out anchor

        keyDelimiter = keyDelimiter || cfg.delimiter.key;
        valDelimiter = valDelimiter || cfg.delimiter.val;
        qs.split(keyDelimiter).forEach(pair => {
          const [key, val] = pair.split(valDelimiter);
          obj[key] = val;
        });
      }
      return obj;
    },
    /**
     * Get an object from a list of objects by searching for a key:value pair
     * @param {Object} obj : literal, json
     * @param {String} val : the value you seek
     * @param {String} key : the key
     * @returns {Object<key,val>} object with matching key, val pair
     */
    getObjectProperty: function (obj, val, key) {
      let o = undefined;
      for (let property in obj) {
        if (obj.hasOwnProperty(property)) {
          if (property === key && obj[property] === val) {
            o = obj;
            break;
          }
          if (typeof obj[property] === 'object') {
            o = this.getObjectProperty(obj[property], val, key);
          }
        }
      }
      return o;
    },
    /**
     * Determine an instance of HTML element
     * @param {object} obj : undecided object
     * @returns {boolean} instance of HTMLElement
     */
    isElement: function (obj) {
      return typeof HTMLElement === 'object' ? obj instanceof HTMLElement : obj && typeof obj === 'object' && obj.nodeType === 1 && typeof obj.nodeName === 'string';
    },
    /**
     * Check if an element is overflown
     * @param {HTMLElement} element 
     * @returns {boolean}
     */
    isElementOverflown: function (element) {
      return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    },
    /**
     * Restore original number value
     * @param {string} val : formatted string value of a number
     * @returns {number} : orignal number value
     */
    parseNumberFormat: function (val) {
      val = val.toString().replace('.', '') || '0';
      return +parseFloat(val);
    },
    /**
     * Validates a floating point number where negatives are allowed
     * @example latitude|longitude
     * @param {Number} n number
     * @returns {Boolean}
     */
    isNumber: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    /**
     * Get Bootstrap media breakpoint from the current window width
     * scss variable: xs: 0, sm: 576, md: 768, lg: 992, xl: 1300
     * @returns {String} xs|sm|md|lg|xl
     */
    getMediaBreakpoint: function () {
      const w = $(window).width();
      let strW = 'xl';
      switch (w) {
        case w < cfg.breakpoints.sm:
          strW = 'xs';
          break;
        case w < cfg.breakpoints.md:
          strW = 'sm';
          break;
        case w < cfg.breakpoints.lg:
          strW = 'md';
          break;
        case w < cfg.breakpoints.xl:
          strW = 'lg';
          break;
      }
      return strW;
    },
    /**
     * Determine if an element has scrolled into view
     * @param {Object} el element
     * @returns {Boolean} true when element scrolled into view
     */
    isScrolledIntoView: function (el) {
      if (!el) {
        return false;
      }
      const elemTop = el.getBoundingClientRect().top;
      const elemBottom = el.getBoundingClientRect().bottom;
      return elemTop >= 0 && elemBottom <= window.innerHeight;
    },
    /**
     * Animated scroll to target element
     * @param {Object} target jquery object
     * @param {Literal} options offsetY
     */
    scrollToElement: function (target, options) {
      if (target && target.length) {
        setTimeout(function () {
          if (!ns.fn.isScrolledIntoView(target.get(0))) {
            $(document.documentElement).add(document.body).animate({
              scrollTop: target.offset().top - (options && options.offsetY || 0)
            });
          }
        }, 400);
      }
    },
    getActiveCulture: function () {
      const {
        cache,
        data
      } = Object.assign(cfg);
      const defaultCulture = document.documentElement.dataset[data.culture];
      const selected = document.querySelectorAll(cache.culture.selected);
      const selectedCulture = selected.length && selected[0].dataset[data.value];
      return defaultCulture.toLowerCase() || selectedCulture.toLowerCase();
    },
    getActiveCountry: function () {
      const culture = this.getActiveCulture();
      return culture && culture.split('-')[1].toLowerCase();
    },
    getActiveLanguage: function () {
      const culture = this.getActiveCulture();
      const language = culture && culture.split('-')[0].toLowerCase();
      return language;
    }
  };

  // 4. NAMESPACE
  return ns;
}(window.jQuery, window.Blastic || {});